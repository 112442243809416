import { Html5Qrcode } from 'html5-qrcode';

document.addEventListener("turbolinks:load", function() {

    
    const scanButton = document.getElementById('start-scan');
    const statusCamera = document.getElementById('status-camera')
    if (!scanButton || !statusCamera) return;
    let html5QrCode = null
    let currentStream = null
    
    if(scanButton){
        scanButton.addEventListener('click', function() {
            const storeId = scanButton.getAttribute('data-store-id');
            const namePartner = statusCamera.getAttribute('data-partner-name');
            const qrboxSize = Math.floor(window.innerWidth * 0.9)

            // Verificar si el escáner ya está activo
            if (!html5QrCode) {
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    const hasCamera = devices.some(device => device.kind === 'videoinput');
                    if (hasCamera) {
                        startScannerQr();
                    } else {
                        requestPermissionCamara();
                    }
                });
            } else {
                stopScanner();
            }

            function requestPermissionCamara() {
                navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
                    .then((stream) => {
                        // Detener el stream de inmediato para liberar la cámara
                        stream.getTracks().forEach((track) => track.stop());
                        startScannerQr();
                    })
                    .catch((err) => {
                        console.error("No se pudo acceder a la cámara:", err);
                        statusCamera.innerHTML = `
                            <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                <strong>${namePartner}</strong>, no hemos encontrado ningún dispositivo para escanear.
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>`;
                    });
            }

            function startScannerQr() {
                // Crear una nueva instancia de html5QrCode cada vez
                html5QrCode = new Html5Qrcode("qr-reader");
                html5QrCode.start(
                    { facingMode: "environment" },
                    { fps: 10, qrbox: qrboxSize },
                    decodedText => {
                        onScanSuccess(decodedText, storeId);
                        stopScanner(); // Detener el escáner después de un escaneo exitoso
                    },
                    errorMessage => {
                        console.log("Error en el escaneo: ", errorMessage);
                    }
                ).then(() => {
                    scanButton.textContent = "Detener Escáner"; // Cambiar texto cuando el escáner está activo
                }).catch(err => {
                    console.error("Error al iniciar el escáner", err);
                    statusCamera.innerHTML = `
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <strong>${namePartner}</strong>, no hemos encontrado ningún dispositivo para escanear.
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>`;
                    // Asegurarse de que el botón vuelva a su estado inicial
                    scanButton.textContent = "Escanear QR";
                    html5QrCode = null;
                });
            }
        });
     

        function stopScanner() {
            if (html5QrCode) {
                html5QrCode.stop().then(() => {
                    html5QrCode = null; // Liberar la instancia
                    scanButton.textContent = "Escanear QR"; // Cambiar el texto del botón cuando el escáner se detiene
                    
                    // Detener el flujo de la cámara si existe
                    if (currentStream) {
                        currentStream.getTracks().forEach(track => track.stop());
                        currentStream = null;
                    }
                }).catch(err => {
                    console.error("Error al detener el escáner", err);
                    scanButton.textContent = "Escanear QR"; // Cambiar el texto incluso si ocurre un error
                    html5QrCode = null;
                });
            }
        }

        document.addEventListener("visibilitychange", function() {
            if (document.hidden) {
                // Detiene el escáner si la página pasa a segundo plano
                stopScanner();
            }
        });
    
        document.addEventListener("turbolinks:before-cache", function() {
            // Detener el escáner y liberar la cámara antes de cambiar de vista
            stopScanner();
        });
    }

    function onScanSuccess(decodedText, storeId) {
        console.log("Decoded Text:", decodedText); // Imprimir el texto decodificado

        let qrData;
        try {
            qrData = JSON.parse(decodedText); // parsear el string JSON
        } catch (e) {
            console.error("Error al parsear el texto decodificado:", e);
            
            return;
        }
        
        $.ajax({
            url: '/stores/' + storeId + '/validator',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({ qr_code: qrData }), // Envía qrData como un objeto
            dataType: 'json',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                if (response.success) {
                    // Agrega el nuevo QR validado al principio de la tabla
                    $("#qr-codes-table").html(response.html);
                    $('#detalles-pedido').removeClass('d-none alert-danger').addClass('d-block alert-success').html('Orden Validada 🤘🏼');
                } else {
                    $('#detalles-pedido').removeClass('d-none alert-success').addClass('d-block alert-danger').html('Error: ' + (response.error || 'Error desconocido'));
                }
                console.log("Respuesta del servidor:", response);
            },
            
            error: function(xhr, status, error) {
                console.log("Error en la solicitud AJAX:", xhr, status, error);
                $('#detalles-pedido').html('Error: ' + error);
                
            }
        });
    }

    const form = document.querySelector('.qr_code'); 
    const input = document.querySelector('input[name="qr_code[order_number]"]');

    if (form) {
        form.addEventListener('submit', () => {
            const trimmedValue = input.value.trim();
            input.value = trimmedValue; // Actualiza el valor del input con el valor recortado
            
        });    
    }
    
  
    const orderForm = document.querySelector('form[action*="validator"]');
    if (orderForm) {
        orderForm.addEventListener('ajax:success', function(event) {
            const [data, status, xhr] = event.detail;
            console.log('Respuesta del formulario:', data);

            if (data.success) {
                $("#qr-codes-table").html(data.html);
                $('#detalles-pedido').removeClass('d-none alert-danger').addClass('d-block alert-success').html('Orden Validada');
            } else {
                $('#detalles-pedido').removeClass('d-none alert-success').addClass('d-block alert-danger').html('Error: ' + (data.error || 'Error desconocido'));
            }
        });
    }

    document.body.addEventListener('click', () => {
        if (statusCamera) {
            statusCamera.innerHTML = ''
        }
    })

   
});
