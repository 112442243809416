import consumer from "./consumer"

let notificationsSubscription; // Variable global para almacenar la suscripción

function createSubscription() {
  if (notificationsSubscription) return;

  notificationsSubscription = consumer.subscriptions.create("NotificationsChannel", {
    connected() {
      console.log("Conectado al canal de notificaciones");
    },

    disconnected() {
      console.log("Desconectado del canal de notificaciones");
    },

    received(data) {
      console.log("Notificación recibida:", data);
      updateNotificationsUI(data);
    }
  });
}

// Función para actualizar la interfaz de notificaciones
function updateNotificationsUI(data) {
  const notificationsLink = document.querySelector('.notifications-link');
  const notificationsPath = notificationsLink ? notificationsLink.dataset.notificationsPath : '/notifications';
  const notificationsContainer = document.getElementById("notifications-container");

  if (window.location.pathname === notificationsPath) {
    if (notificationsContainer) {
      notificationsContainer.insertAdjacentHTML("afterbegin", data.notification);
      markNotificationAsRead(data.notification_id);
    }
    removeBadge(); 

    const noNotificationsMessage = document.getElementById("no-notifications-message");
    if (noNotificationsMessage) noNotificationsMessage.remove();
  } else {
    if (notificationsLink) {
      let badge = notificationsLink.querySelector(".badge-notify");
      if (badge) {
        if (badge.textContent !== data.unread_count.toString()) { // Actualiza solo si el conteo cambió
          badge.textContent = data.unread_count;
        }
      } else {
        badge = document.createElement('span');
        badge.className = 'badge-notify';
        badge.textContent = data.unread_count;
        notificationsLink.appendChild(badge);
      }
    }

    if (notificationsContainer) {
      notificationsContainer.insertAdjacentHTML("afterbegin", data.notification);
    }
  }
}

// Función para marcar la notificación como leída en el backend
async function markNotificationAsRead(notificationId) {
  try {
    const response = await fetch(`/notifications/${notificationId}/mark_as_read`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Error al marcar la notificación como leída');
    }

    console.log('Notificación marcada como leída');
  } catch (error) {
    console.error('Error de red:', error);
  }
}

// Función para eliminar el badge si estamos en la página de notificaciones
function removeBadge() {
  const notificationsLink = document.querySelector('.notifications-link');
  if (notificationsLink) {
    const badge = notificationsLink.querySelector(".badge-notify");
    if (badge) {
      badge.remove();
    }
  }
}

// Manejar eventos de Turbolinks para mantener la suscripción y el badge actualizados
document.addEventListener("turbolinks:load", () => {
  createSubscription();
});

document.addEventListener("turbolinks:before-render", () => {
  if (notificationsSubscription) {
    notificationsSubscription.unsubscribe();
    notificationsSubscription = null;
  }
});


