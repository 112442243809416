document.addEventListener("turbolinks:load", ()=> {
   
       $('.selectize').selectize({
             
       });
       $('.selectize-max3').selectize({
              maxItems: 3
       });
       $('.selectize-max1').selectize({
              maxItems: 1
       });
       $('.selectize-max2').selectize({
              maxItems: 2
       });
       
})