// update time zone if user change location
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
fetch('/update_timezone', {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    body: JSON.stringify({ timezone: timezone })
}).then(() => {
    console.log('Zona horaria actualizada en el servidor:', timezone);
  
});