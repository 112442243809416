document.addEventListener("turbolinks:load", () => {
  const dateRangeForm = document.getElementById('date-range-form');
  const dateRangeInput = document.getElementById('datetimerange-input1');

  if (dateRangeInput) {
    const picker = new DateRangePicker(dateRangeInput, 
      {
        locale: {
        format: 'DD-MM-YYYY', // Configura el formato de la fecha aquí
        firstDay: 1,
        daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      },
      // showDropdowns: true,
      // showWeekNumbers: true,
      // showISOWeekNumbers: true,
      autoApply: true,
      // linkedCalendars: true,
      // autoUpdateInput: false,
    });

    document.getElementById('last-7-days').addEventListener('click', () => {
      dateRangeInput.value = `${moment().subtract(6, 'days').format('DD-MM-YYYY')} - ${moment().format('DD-MM-YYYY')}`;
      dateRangeForm.submit();
    });

    document.getElementById('last-30-days').addEventListener('click', () => {
      dateRangeInput.value = `${moment().subtract(29, 'days').format('DD-MM-YYYY')} - ${moment().format('DD-MM-YYYY')}`;
      dateRangeForm.submit();
    });

    document.getElementById('last-6-months').addEventListener('click', () => {
      dateRangeInput.value = `${moment().subtract(6, 'months').format('DD-MM-YYYY')} - ${moment().format('DD-MM-YYYY')}`;
      dateRangeForm.submit();
    });
  }
});
