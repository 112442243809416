import consumer from "./consumer";

document.addEventListener("turbolinks:load", () => {
  const storeOrder = document.querySelector('.store-order');

  if (storeOrder) {
    const storeId = storeOrder.getAttribute('data-store-id');

    // Crear una suscripción al canal de notificaciones de la tienda específica
    const subscription = consumer.subscriptions.create(
      { channel: "OrderNotificationsChannel", store_id: storeId },
      {
        connected() {
          console.log("Conectado al canal de notificaciones para la tienda: " + storeId);
        },

        disconnected() {
          console.log("Desconectado del canal de notificaciones");
        },

        received(data) {
          if (data.type === 'order_notified') {
            // Actualizar el estado de la orden si ha sido notificada
            const orderElement = document.getElementById(`order-${data.order_id}`);
            if (orderElement) {
              const button = orderElement.querySelector('.notify-order-ready-btn');
              if (button) {
                button.textContent = 'Notificación enviada';
                button.style.pointerEvents = 'none';
                button.classList.remove('btn-success');
                button.classList.add('btn-secondary');

                // Cambiar la apariencia de la tarjeta
                orderElement.style.opacity = 0.6;
                orderElement.style.backgroundColor = '#e0e0e0';
              }
            }
          }
        }
      }
    );
  }
});
