document.addEventListener("turbolinks:load", () => {

    var navbar = document.getElementById("sticky");
    var stickyPosition;

    if (navbar) {
        if (window.innerWidth <= 768) {
            stickyPosition = navbar.offsetTop ;
        } else {
            stickyPosition = navbar.offsetTop ;
        }

        window.addEventListener('resize', function() {
            if (window.innerWidth <= 768) {
                stickyPosition = navbar.offsetTop;
            } else {
                stickyPosition = navbar.offsetTop ;
            }
        });

        function stickyFunction() {
            if (window.scrollY >= stickyPosition) {
                navbar.classList.add("sticky");
            } else {
                navbar.classList.remove("sticky");
            }
        }

        window.addEventListener('scroll', stickyFunction);
    }


    // Funcion para resaltar el boton de la seccion actual
    function highlightSection() {
        // Obtener la posición actual de desplazamiento
        const scrollPosition = window.scrollY;

        // Obtener todas las secciones y los enlaces 
        const sections = document.querySelectorAll('[id^="section-"]');
        const navLinks = document.querySelectorAll('.sec-btn-2');

        // Iterar sobre todas las secciones
        sections.forEach(section => {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.clientHeight;

            // Comprobar si la posicion de desplazamiento esta dentro de la seccion actual
            if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
                // Resaltar el enlace de navegacion correspondiente
                navLinks.forEach(link => {
                    const linkSection = link.querySelector('a').getAttribute('data-section');
                    if (linkSection === section.getAttribute('id')) {
                        link.classList.add('btn-selected');
                        link.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }); // centrar el scroll h
                    } else {
                        link.classList.remove('btn-selected');
                        
                    }
                });
            }
        });
    }


    // Llamar a la funcion cuando se desplaza la pagina
    window.addEventListener('scroll', highlightSection);

    // Desplazamiento suave al hacer clic en un enlace de seccion
    const sectionLinks = document.querySelectorAll('.section-link');
        sectionLinks.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();
    
            const sectionId = e.currentTarget.getAttribute('href').slice(1);
            const sectionElement = document.getElementById(sectionId);
            if (sectionElement) {
                const navbarHeight = navbar.offsetHeight; // Obtener la altura de la barra de navegación "sticky"
                const topPosition = sectionElement.getBoundingClientRect().top + window.scrollY - navbarHeight - 60;
                window.scrollTo({
                    top: topPosition,
                    behavior: "smooth"
                });
            }
        });
    });

    // Llamar a la funcion al cargar la página para resaltar la seccion actual
    highlightSection();
});




