function locationHasChanged(currentLocation, storedLocation) {
    const earthRadiusKm = 6371; // Radio de la Tierra

    function toRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    var dLat = toRadians(currentLocation.latitude - storedLocation.latitude);
    var dLon = toRadians(currentLocation.longitude - storedLocation.longitude);

    var lat1 = toRadians(storedLocation.latitude);
    var lat2 = toRadians(currentLocation.latitude);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    var distance = earthRadiusKm * c;

    const thresholdKm = 0.5; // cambio en km aceptable
    return distance > thresholdKm;
}

var ajaxCallNeeded = true;
function getLocationAndUpdateStores() {
    if (!ajaxCallNeeded) {
        return;
    }

    let getLocationPromise = new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            const locationPermission = localStorage.getItem('locationPermission');
            const cookieValue = document.cookie.split('; ').find(row => row.startsWith('location_allowed='));
		    const locationAllowed = cookieValue ? cookieValue.split('=')[1] : null;

            // console.log('Location Permission from localStorage:', locationPermission);
            // console.log('Location Allowed from cookie:', locationAllowed);
    
            // Verifica si el permiso fue concedido previamente ya sea por localStorage o por cookie
            if (locationPermission === 'granted' || locationAllowed === 'true') {
                navigator.geolocation.getCurrentPosition(function(position) {
                    // Actualiza la cookie para extender la caducidad
                    document.cookie = "location_allowed=true; max-age=31536000; path=/; Secure; SameSite=Lax";
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        permissionGranted: true
                    });
                }, function(error) {
                    reject("Error al obtener la geolocalización: " + error.message);
                });
            } else {
                // Solicitar permiso de geolocalizacion ya que no se encontro permiso previo
                navigator.geolocation.getCurrentPosition(function(position) {
                    localStorage.setItem('locationPermission', 'granted');
                    document.cookie = "location_allowed=true; max-age=31536000; path=/; Secure; SameSite=Lax";
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        permissionGranted: true
                    });
                }, function(error) {
                    reject("Error al obtener la geolocalización: " + error.message);
                });
            }
        } else {
            reject("Tu navegador es incompatible con la geolocalización");
        }
    });
    


    getLocationPromise.then((currentLocation) => {
        console.log("lat-long :", currentLocation);
        let storedLocation = document.cookie.split('; ').find(row => row.startsWith('last_location='));
            if (storedLocation) {
                storedLocation = decodeURIComponent(storedLocation.split('=')[1]);
                storedLocation = storedLocation.split('&').map(Number);
                
            if (locationHasChanged(currentLocation, {latitude: storedLocation[0], longitude: storedLocation[1]})) {    
                updateLocationAndStores(currentLocation);
            } else {
                console.log("La ubicacionn no ha cambiado significativamente.");
            }
        } else {
            
            updateLocationAndStores(currentLocation);
        }
       
        
    }).catch((error) => {
        console.error("Error al obtener la ubicación:", error);
    });


}

function updateLocationAndStores(location) {
    var token = $('meta[name="csrf-token"]').attr('content');
    var data = {
        latitude: location.latitude,
        longitude: location.longitude
    };

    // Actualiza la cookie con la nueva ubicacion
    document.cookie = "last_location=" + location.latitude + "&" + location.longitude + ";path=/";

    // window.location.reload();
    $.ajax({
        url: '/stores',
        type: 'get',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token);
        },
        data: data,
        dataType: "script",
        success: function() {
            ajaxCallNeeded = false; // Desactivar futuras llamadas AJAX
        }
        
    });
    
}

// funcion al cargar la pagina
$(document).ready(function() {
    getLocationAndUpdateStores();
});

