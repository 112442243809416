document.addEventListener('DOMContentLoaded', function() {
    const openInAppButton = document.getElementById('open-in-app');
  
    // Detecta si el dispositivo es Android
    const isAndroid = /android/i.test(navigator.userAgent);
  
    // Verifica que el botón existe, que no está en modo standalone y que es Android
    if (openInAppButton && !window.matchMedia('(display-mode: standalone)').matches && isAndroid) {
      openInAppButton.style.display = 'block';
  
      // Redirecciona a la tienda específica usando el slug
      if(openInAppButton){
          openInAppButton.addEventListener('click', function() {
            const storeSlug = openInAppButton.getAttribute('data-store-slug');
            window.location.href = `/${storeSlug}`;
          });
      }
    }
  });
  