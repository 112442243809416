import consumer from "./consumer"

consumer.subscriptions.create("AccountChannel", {

  received(data) {
    if (data.message === "Orden validada exitosamente" && data.redirect_path) {
      setTimeout(() => {
        window.location.href = data.redirect_path;
      }, 2000); 
    }
  }
});
