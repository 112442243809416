
document.addEventListener("turbolinks:load", () => {
    
//     // Whole Script Strict Mode Syntax
//     "use strict";

//     // Book Table Img Slider JS

    var book_table_img_slider = new Swiper(".book-table-img-slider", {
        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        speed: 2000,

        effect: "coverflow",
        coverflowEffect: {
            rotate: 3,
            stretch: 2,
            depth: 100,
            modifier: 5,
            slideShadows: false,
        },
        loopAdditionalSlides: true,

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },

        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    // Book Table Img Slider JS End


    // Team Slider JS

    var team_slider = new Swiper(".team-slider", {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        speed: 2000,

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },

        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },

        breakpoints: {
            0: {
                slidesPerView: 1.2,
            },
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 3,
            },
        },
    });

//     // Team Slider JS End

//     // Project Tabbing JS

//     // jQuery(".filters").on("click", function () {
//     //     jQuery("#menu-dish").removeClass("bydefault_show");
//     // });

//     // $(function () {
//     //     var filterList = {
//     //         init: function () {
//     //             // MixItUp plugin
//     //             // http://mixitup.io
//     //             $("#menu-dish").mixItUp({
//     //                 selectors: {
//     //                     target: ".dish-box-wp",
//     //                     filter: ".filter",
//     //                 },
//     //                 animation: {
//     //                     effects: "fade",
//     //                     easing: "ease-in-out",
//     //                 },
//     //                 load: {
//     //                     filter: ".all, .breakfast, .lunch, .dinner",
//     //                 },
//     //             });
//     //         },
//     //     };
//         // Run the show!
//     //     filterList.init();
//     // });

//     // Project Tabbing JS End

//     // Menu Open JS

    jQuery(".menu-toggle").click(function () {
        jQuery(".main-navigation").toggleClass("toggled");
        
        
        jQuery(".sections-container").toggleClass("hidden");
    });

//     // Menu Open JS End

//     // DropDown Menu JS

//     jQuery(".dropdown-items").click(function () {
//         var cur = jQuery(this).closest(".dropdown-items");
//         jQuery(".dropdown-items").not(cur).removeClass("dropdown-open");
//         jQuery(this).closest(".dropdown-items").toggleClass("dropdown-open");
//     });

//     // DropDown Menu JS End

//     // Mobile Navigation Menu Removeclass

    // jQuery(".header-menu ul li a").click(function () {
    //     jQuery(".main-navigation").removeClass("toggled");
    // });

//     // Mobile Navigation Menu Removeclass End

//     // Sticky Header JS

    // jQuery(window).scroll(function () { // this will work when your window scrolled.
    //     var height = jQuery(window).scrollTop(); //getting the scrolling height of window
    //     if (height > 20) {
    //         jQuery(".site-header").addClass("sticky_head");
    //     } else {
    //         jQuery(".site-header").removeClass("sticky_head");
    //     }
    // });

//     // Sticky Header JS End

     //Mouse Parallax

   
     var parallaxElement = $('.js-parallax-scene').get(0);
     if (parallaxElement) {
         var scene = new Parallax(parallaxElement);
     }
    

     //Mouse Parallax End

//     // Scroll To Top JS

//     jQuery('#scrollToTop').click(function () {
//         jQuery("html, body").animate({ scrollTop: 0 }, 600);
//         return false;
//     });

//     // Scroll To Top JS End

//     // Active Menu JS

//     var sections = $('section'),
//         nav = $('nav'),
//         nav_height = nav.outerHeight();

//     $(window).on('scroll', function () {
//         var cur_pos = $(this).scrollTop();

//         sections.each(function () {
//             var top = $(this).offset().top - nav_height,
//                 bottom = top + $(this).outerHeight();

//             if (cur_pos >= top && cur_pos <= bottom) {
//                 nav.find('a').removeClass('active-menu');
//                 sections.removeClass('active-menu');

//                 $(this).addClass('active-menu');
//                 nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('active-menu');
//             }
//         });
//     });

//     nav.find('a').on('click', function () {
//         var $el = $(this),
//             id = $el.attr('href');

//         $('html, body').animate({
//             scrollTop: $(id).offset().top - nav_height
//         }, 200);

//         return false;
//     });

//     // Active Menu JS End

//     jQuery(window).on('load', function () {
//         $('.page-loader').fadeOut();
//         $('body').removeClass('body-fixed');
//     });
});









