// Script para verificar si el usuario conincide con la misma suscripcion que inicio sesion (solve: multi-usuario con un dispositivo)
document.addEventListener("turbolinks:load", () => {
   
    // Función para enviar la suscripción al backend
    function sendSubscription(subscription) {
        const accountId = document.body.getAttribute('data-account-id');
        
        fetch('/push_subscriptions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({
            subscription: subscription.toJSON(),
            account_id: accountId
          })
        }).then(function(response) {
          if (response.ok) {
            console.log('Suscripción almacenada en el backend.');
          } else {
            console.error('Error al almacenar la suscripción en el backend');
          }
        }).catch(function(error) {
          console.error('Error al enviar la suscripción al backend:', error);
        });
    }
  
    function checkAndSendSubscription() {
        navigator.serviceWorker.ready.then((registration) => {
          registration.pushManager.getSubscription().then((subscription) => {
            if (subscription) {
              console.log("Verificando suscripción para el usuario actual.");
              sendSubscription(subscription);
            } else {
              console.log("No se encontró una suscripción existente.");
            }
          }).catch((error) => {
            console.error("Error al verificar la suscripción:", error);
          });
        });
    }
 
    const verifySubscription = document.body.getAttribute("data-verify-subscription");
    const accountId = document.body.getAttribute("data-account-id");
 
    if (verifySubscription === "true" && accountId) {
        console.log("Iniciando checkAndSendSubscription por cambio de usuario.");
        checkAndSendSubscription();
    } else {
        console.log("No se encontró account ID o el flag de verificación es falso.");
    }
});
 