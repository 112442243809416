document.addEventListener("turbolinks:load", function() {
  // Selecciona el botón de envío basado en el atributo data-role
  var botonEnviar = document.querySelector('[data-role="submit-button"]');
  if (botonEnviar) {
    botonEnviar.addEventListener('click', function(e) {
      // Muestra el spinner cuando se haga clic en el botón
      var spinner = document.getElementById('spinner');
      if (spinner) {
        spinner.style.display = 'block';
      } else {
        console.log("El spinner no se encontró");
      }
    });
  } else {
    
  }
});

