document.addEventListener("turbolinks:load", function() {  
  document.querySelectorAll('.image-upload').forEach(function(input) {
      input.addEventListener('change', function() {
          if (this.files && this.files[0]) {
              let reader = new FileReader();
              let previewElementId;
              
              switch (input.getAttribute('name')) {
                  // Ajustar según tus campos
                  case 'store[avatar]':
                      previewElementId = 'avatar-preview';
                      break;
                  case 'store[header]':
                      previewElementId = 'header-preview';
                      break;
                  case 'product[image_products]':
                      previewElementId = 'image_products-preview';
                      break;
                  case 'product[image_products_second]':
                      previewElementId = 'image_products_second-preview';
                      break;
              }

              reader.onload = function(e) {
                  let imgPreview = document.getElementById(previewElementId);
                  if (imgPreview) {
                      imgPreview.src = e.target.result;
                      imgPreview.classList.remove('d-none'); 
                  } else {
                      
                  }
              };

              reader.readAsDataURL(this.files[0]);
          }
      });
  });
});

