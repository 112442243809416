// document.addEventListener("turbolinks:load", () => {
//   const cartInfo = document.getElementById("cart-info");
//   if (cartInfo) {

//     const storeId = cartInfo.getAttribute("data-store-id");
//     const cartId = cartInfo.getAttribute("data-cart-id");
  
//     let debounceTimeout;
  
//     document.querySelectorAll(".update-quantity").forEach((button) => {
//       button.addEventListener("click", (event) => {
//         const orderItemId = button.dataset.orderItemId;
//         const action = button.dataset.action;
//         const productStock = parseInt(button.dataset.productStock, 10);
//         const quantityInput = button.parentElement.querySelector(".quantity-input");
//         const incrementButton = button.parentElement.querySelector(".update-quantity[data-action='increment']");
//         const subtotalElement = button.closest(".cart-item").querySelector(".subtotal");
//         const cartCounter = document.querySelector(".counter-cart");
//         if (action === "decrement" && parseInt(quantityInput.value) === 1) {
//           return;
//         }
  
//         // Actualiza la cantidad en la interfaz por click
//         const currentQuantity = parseInt(quantityInput.value);
//         const newQuantity = action === "increment" ? currentQuantity + 1 : Math.max(1, currentQuantity - 1);
//         quantityInput.value = newQuantity;
//         // disable button + after stock max
//         incrementButton.disabled = newQuantity >= productStock;

//         clearTimeout(debounceTimeout);
//         debounceTimeout = setTimeout(() => {
//           fetch(
//             `/stores/${storeId}/carts/${cartId}/update_item_quantity/${orderItemId}`,
//             {
//               method: "PATCH",
//               headers: {
//                 "Content-Type": "application/json",
//                 "X-CSRF-Token": document.querySelector("[name='csrf-token']")
//                   .content,
//               },
//               // body: JSON.stringify({ quantity_action: action }),
//               body: JSON.stringify({ final_quantity: newQuantity }),
//             }
//           )
//             .then((response) => response.json())
//             .then((data) => {
//               if (data.success) {
//                 const subtotalElement = document.getElementById("subtotal");
//                 if (subtotalElement) {
//                   subtotalElement.textContent = `${data.new_subtotal}`;
//                 }
//                 const discountElement = document.getElementById("discount");
//                 if (discountElement) {
//                   discountElement.textContent = `-${data.new_discount}`;
//                 }
//                 const totalElement = document.getElementById("total");
//                 if (totalElement) {
//                   totalElement.textContent = `${data.new_total}`;
//                 }
//                 const platform_fee = document.getElementById("platform_fee");
//                 if (platform_fee) {
//                   platform_fee.textContent = `${data.new_fee}`
//                 }
//                 const itemSubtotalElement = document.getElementById(
//                   `subtotal-${orderItemId}`
//                 );
//                 if (itemSubtotalElement) {
//                   itemSubtotalElement.textContent = `${data.new_subtotal_item}`;
//                 }
//                 const discountItem = document.getElementById(`item-discount-${orderItemId}`)
//                 if (discountItem){
//                   discountItem.textContent = `${data.new_price_discount_item}`
//                 }
//                 if (cartCounter) {
//                   cartCounter.textContent = data.total_cart_items;
//                 }

//                 const decrementButton = button.closest(".input-group").querySelector(".update-quantity[data-action='decrement']");
//                 const removeButton = button.closest(".input-group").querySelector(".remove-item");

//                 if (data.new_quantity === 1) {
//                   if (decrementButton) {
//                     decrementButton.style.display = "none";
//                   }
//                   if (removeButton) {
//                     removeButton.style.display = "inline-block";
//                   }
//                 } else {
//                   if (decrementButton) {
//                     decrementButton.style.display = "inline-block";
//                   }
//                   if (removeButton) {
//                     removeButton.style.display = "none";
//                   }
//                 }

//               }
//             });
//         }, 300);
//       });
//     });
//   }
// });

document.addEventListener("turbolinks:load", () => {
  const cartInfo = document.getElementById("cart-info");
  if (cartInfo) {
    const storeId = cartInfo.getAttribute("data-store-id");
    const cartId = cartInfo.getAttribute("data-cart-id");

    let debounceTimeout;

    // Estado de los botones de incremento
    document.querySelectorAll(".update-quantity[data-action='increment']").forEach(button => {
      const quantityInput = button.parentElement.querySelector(".quantity-input");
      const productStock = parseInt(button.dataset.productStock, 10);
      const stockActive = button.dataset.stockActive === 'true';
      // Asegura que el botón solo se deshabilite si stockActive es true y la cantidad supera el stock
      button.disabled = stockActive && parseInt(quantityInput.value) >= productStock;
    });

    document.querySelectorAll(".update-quantity").forEach(button => {
      button.addEventListener("click", event => {
        const orderItemId = button.dataset.orderItemId;
        const action = button.dataset.action;
        const productStock = parseInt(button.dataset.productStock, 10);
        const stockActive = button.dataset.stockActive === 'true';
        const quantityInput = button.parentElement.querySelector(".quantity-input");
        const incrementButton = button.parentElement.querySelector(".update-quantity[data-action='increment']");

        if (action === "decrement" && parseInt(quantityInput.value) === 1) {
          return;
        }

        const currentQuantity = parseInt(quantityInput.value);
        const newQuantity = action === "increment" ? currentQuantity + 1 : Math.max(1, currentQuantity - 1);
        quantityInput.value = newQuantity;

        // Habilita el botón si stockActive es falso o si la nueva cantidad aún está por debajo del stock
        incrementButton.disabled = stockActive && newQuantity >= productStock;

        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          fetch(`/stores/${storeId}/carts/${cartId}/update_item_quantity/${orderItemId}`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            },
            body: JSON.stringify({ final_quantity: newQuantity }),
          })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              updateUI(data, orderItemId, newQuantity, incrementButton, button);
            }
          });
        }, 300);
      });
    });
  }
});

function updateUI(data, orderItemId, newQuantity, incrementButton, button) {
  // Actualización de los elementos del DOM con los nuevos datos
  document.getElementById("subtotal").textContent = data.new_subtotal;
  document.getElementById("discount").textContent = `-${data.new_discount}`;
  document.getElementById("total").textContent = data.new_total;
  document.getElementById("platform_fee").textContent = data.new_fee;
  document.getElementById(`subtotal-${orderItemId}`).textContent = data.new_subtotal_item;
  document.getElementById(`item-discount-${orderItemId}`).textContent = data.new_price_discount_item;
  document.querySelector(".counter-cart").textContent = data.total_cart_items;


  const decrementButton = button.closest(".input-group").querySelector(".update-quantity[data-action='decrement']");
  const removeButton = button.closest(".input-group").querySelector(".remove-item");
  const stockActive = button.dataset.stockActive === 'true';  
  const productStock = parseInt(button.dataset.productStock, 10);  

  decrementButton.style.display = newQuantity === 1 ? 'none' : 'inline-block';
  removeButton.style.display = newQuantity === 1 ? 'inline-block' : 'none';

  incrementButton.disabled = stockActive && newQuantity >= productStock;
}
