// En checkout url, el script establece en session storage con el indicador fromPayment, si navega de regreso el script verifica si fromPyment
// Se elimina el indicador y se recarga la pagina 
// concl. Metodo de refuerzo (1) si la instancia de pago no se re establece con valores nuevos luego de modificar con un back url

document.addEventListener('turbolinks:load', function() {

    const pathNameCheck = window.location.pathname.match(/stores\/\d+\/checkout_mp/)
    const pathNameCart = window.location.pathname.match(/stores\/[^\/]+\/carts\/\d+/)

    if (pathNameCheck) {
        sessionStorage.setItem('fromPayment', 'true');
        console.log("Path: ",pathNameCheck);
    }

    if (pathNameCart) {
        if (sessionStorage.getItem('fromPayment') === 'true') {
            sessionStorage.removeItem('fromPayment');
            console.log("Path: ",pathNameCart);
            window.location.reload(); 
        }
    }
});
